.login .block .head h3{
    text-align: center;
    transform: translateY(20px);
}
.login .block{
    display: block;
    height: 350px;
    width: 400px;
    box-shadow: 0px 0px 6px black;
    border-radius: 10px;
    margin: 200px 600px;
}
.login .block .innerblock{
    display: block;
    height: 200px;
    width: 300px;
    margin: 0px 50px;
    transform: translateY(50px);
}
.login .block .innerblock #username , .login .block .innerblock #password{
    padding: 15px 70px;
    border-radius: 20px;
    border: none;
    outline: none;
}
.login .block .innerblock button{
    padding: 15px 40px;
    border: none;
    border-radius: 20px;
}
.login .block{
    text-align: center;
}